.auth05{
    /* border: 2px solid red; */
    /* height: 100vh; */
    border-bottom: 2px solid turquoise;
    padding: 10rem 0;
    display: grid;    
    place-content: center;
    
}
.auth05-h1 h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    margin-top: 2rem;
    margin-bottom: 5rem;
    padding: 2rem 0;
    background: #1A202C;
    color: white;  
    border-bottom: 2px solid turquoise;  
}
    


.auth05-heading{
    /* border: 2px solid blue; */
   
    text-align: center;
    
}
.auth05-container{
    margin-top: 2rem;
    display: flex;
    /* flex-wrap: wrap; */
    /* gap: 2rem; */
}
.auth05-form{
    flex: 1 1 50rem;
    height: 40rem;
    /* border: 2px solid black; */
    
    background: white;   
    
}
.auth05-image{
    flex: 1 1 60rem;
    height: 40rem;
    
}
.auth05-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.auth05-input h1{
    color: black;
    text-align: center;
    font-size: 2rem;
    font-weight: lighter;
    margin-bottom: 2rem;
}
.auth05-heading h1{
    font-size: 3.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
}
.auth05-heading p{
    font-size: 1.5rem;
    color: gray;
}
.auth05-heading p a{
    color: lightskyblue;  

}
.auth05-input{
/* border: 2px solid orange; */
/* text-align: center; */
padding: 2rem;
margin-top: 3rem;
background: white;
color: black;

/* border-radius: 1rem; */
}
.auth05-p{
    font-size: 1.5rem;
    margin:1rem 0;
}
.auth05-x{
    padding: 0.8rem;
    width: 100%;
    background: white;
color: black;
    border: none;
    outline: 2px solid grey;
    border-radius: 0.5rem;
}
.auth05-checkbox{
    margin-top: 2rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;
    
}
.auth05-checkbox p{
    font-weight: lighter;
    font-size: 1.5rem;
}
.auth05-checkbox a{
    color: lightskyblue;
    font-size: 1.5rem;
}
.auth05-btn{
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #3182CE;
    color: white;
    border: none;
    cursor: pointer;
    
}
.auth05-btn:hover{
    background-color: #3182ce9e;
    transition: 0.5s;
}
@media(max-width:800px){
    .auth05-container{
      flex-wrap: wrap;
    }
}