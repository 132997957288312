.testi01{
    /* border: 2px solid red; */
    padding-top: 10rem;
    padding-bottom: 5rem;
}
.testi01-heading h1{
    font-size: 5rem;
    text-align: center;
    font-weight: lighter;
    margin-bottom: 5rem;
}
.testi01-container{
    /* border: 2px solid blue; */
    display: flex;
    flex-wrap: wrap;
    background-color: black;
}
.testi01-items{
    /* border: 2px solid green; */
    flex: 1 1 38rem;
    text-align: center;
    padding: 2rem;
}
.testi01-star{
    color: orange;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}
.testi01-text p{
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
.testi01-image{
    width: 8rem;
    height: 8rem;
    display: block;
    margin: auto;
    margin-bottom: 2rem;
    
}
.testi01-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10rem;
    cursor: pointer;
}
.testi01-name p{
    font-size: 1.5rem;
}

@media(max-width:500px){
    .testi01-heading h1{
        font-size: 3rem;
     
    }
}