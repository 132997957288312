.sub04-contact{
    /* height: 80vh; */
    
    /* border: 0.1px solid red; */
    background-color: #1A1A1A;
}
.sub04-contact-container{
    padding: 10rem 0;
    /* border: 2px solid blue; */
    height: 100%;
}
.sub04-contact-items{
    padding: 5rem;
    /* border: 2px solid green; */
    display: flex;
    /* flex-wrap: wrap; */
    /* height: 100%; */
    justify-content: center;
    /* align-items: center; */
/* position: relative; */
/* height: 70%; */
}
.sub04-contact-textbox1{
/* border: 2px solid orange; */
flex: 1 1 400px;
/* position: absolute; */
width: 400px;
height: 400px;
/* top: 15%;
z-index: 2; */
background-color: #EAC60B;
padding: 2rem;
color: black;

}
.sub04-contact-textbox1 h1{
margin-bottom: 2rem;
font-size: 2.5rem;
font-family: 'Oswald', sans-serif;
}
.sub04-contact-textbox1 p {
    margin-bottom: 2rem;
    font-size: 1.8rem;
}
.sub04-contact-textbox2{
padding: 2rem;
flex: 1 1 800px;
display: flex;
flex-direction: column;
justify-content: center;
width: 800px;
height: 500px;
/* border: 2px solid purple; */
/* position: absolute;
top: 10%;
left: 30%; */
background-color: white;
/* align-items: center; */
/* z-index: 1; */


}
.sub04-input{
    /* margin-top: 5rem; */
    padding: 2rem;
    /* border: 2px solid red; */
    /* width: 600px; */
    /* margin-left: 15rem;        */

    
}
.sub04-textarea{
    margin-top: 2rem;
    /* width: 600px;
    margin-left: 15rem; */
    padding: 2rem;
}
.sub04-btn-contact{
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid black;
    font-size: 1.5rem;
    padding: 1rem 3rem;
    text-align: center; 
    color: black;
    display: block;
    margin: auto;
    
}

@media(max-width:900px){
    .sub04-contact-textbox1{
        /* border: 2px solid orange; */
        flex: 1 1 400px;
        /* position: absolute; */
        width: 400px;
        height: 50rem;
        /* top: 15%;
        z-index: 2; */
        background-color: #EAC60B;
        padding: 2rem;
        
        }
}

@media(max-width:770px){
    .sub04-contact-items{
       flex-wrap: wrap;
    }
    .sub04-contact-textbox1{
       
        height: 40rem;
       
        
        }
}