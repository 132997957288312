.footer-center{
    padding-bottom: 5rem;
}
.fcenter-container{
    padding-top: 1rem;
    /* border: 2px solid blue; */
    text-align: center;
    background-color:#F7FAFC;
    color: black;
    border-top: 2px solid gray;
    
}
.fcenter-text p{
    margin-top: 1rem;
}