.hero01 {
    padding: 5rem 0;
    /* border: 2px solid red; */
    height: 100vh;
    background-image: url(./hero01.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* margin-top: 10rem; */
    
}
.hero01-container{
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;    
    color: black;
}
.hero01-container h1{
    font-size: 4rem;
}
.hero01-container p{
    font-size: 2rem;
    margin-bottom: 2rem;
}
.btn-hero1{
    text-decoration: none;
    font-size: 2rem;
    padding: 2rem;
    text-transform: uppercase;
    background: black;
    color: white;
    
    border: 2px solid black;
    letter-spacing: 0.5rem;
    outline: none;
    text-align: center;
    align-items: center;
    cursor: pointer;
    
}
.hero01-heading h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    margin-top: 2rem;
    padding: 2rem 0;
    background: #1A202C;
    color: white;  
    
    

}
