.testi02{
    /* border: 2px solid red; */
    border-top: 2px solid turquoise;
    padding: 5rem 0;
}
.testi02-heading h1{
    text-align: center;
    font-size: 5rem;
    font-weight: lighter;
    margin-bottom: 5rem;
}
.testi02-para p{
    font-size: 3rem;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 3rem;
}
.testi02-image{
    width: 8rem;
    height: 8rem;
    display: block;
    margin: auto;    
    margin-bottom: 2rem;
}
.testi02-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10rem;
    
}
.testi02-name p{
    text-align: center;
    font-size: 1.5rem;
}
.testi02-position p{
    text-align: center;
    font-size: 1.5rem;
    color: grey;
}

@media(max-width:500px){
    .testi02-heading h1{
       
        font-size: 3rem;
        
    }
    .testi02-para p{
        font-size: 2rem;
     
    }
}