.withlogo{
    /* border: 2px solid turquoise; */
    padding: 5rem;
    /* background: #1A202C; */
    background:#171923;
    margin-top: 5rem;
    
    
}
.withlogo-heading h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    margin-bottom: 5rem;
}
.wlogo-container{
   
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
   justify-content: space-between;
    background: #F7FAFC;
    color: black;
}
    
    
.wlogo-items{
    /* border: 2px solid green;         */
    /* margin-left: 2.5rem; */
    padding: 0 2rem;
    /* flex: 1 1 20rem; */
    width: 20rem;
    /* max-width: 20rem; */
    
}
.wlogo-heading h1{
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight:400;
    text-decoration: underline;
}
.wlogo-list ul{
    /* border: 2px solid red; */
    list-style: none;
    line-height: 3rem;
    font-size: 1.3rem;
    
}

/* .wlogo-list li:hover{
    border-bottom: 1px solid black;
    transition: 0.5s;
} */
.wlogo-list a{
    color: black;  
    text-decoration: none;  
}
.wlogo-list a:hover{
    text-decoration: underline;
}


.wlogo-image{
width: 15rem;

}
.wlogo-image img{
        
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.wlogo-image p{
    
    
}
@media(max-width:400px){
    .withlogo-heading h1{
      
        font-size: 2.3rem;
        
    }
}

