.sub03-contact{
    /* border: 2px solid red; */
    padding: 5rem 0;
    background: white;
}
.sub03-contact-heading h1{
    text-align: center;
    font-size: 5rem;
    margin-bottom: 5rem;    
    color: black;
    font-weight: lighter;
}
.sub03-contact-container{
    /* border: 2px solid blue; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-wrap: wrap;
    gap: 2rem;
}
.sub03-contact-image{
    padding:0 3rem;
    /* border: 1px solid black; */
    /* width: 50rem; */
    flex: 1 1 60rem;
    height: 40rem;
}
.sub03-contact-image img{
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
    box-shadow: 10px 5px 5px rgba(128, 128, 128, 0.342) ;
}
.sub03-contact-text{
/* border: 1px solid orange; */
padding:0 3rem;
/* width: 50rem; */
flex: 1 1 60rem;
height: 40rem;
display: flex;
flex-direction: column;
}
.sub03-input{
    padding: 1.5rem;
    border: none;
    border-bottom: 2px solid black;
    margin-bottom: 2rem;
}
.sub03-textarea{
    padding: 1.5rem;
    border: none;
    border-bottom: 2px solid black;
    margin-bottom: 2rem;
}
.sub03-contact-btn{
    font-size: 2rem;
    display: block;
    margin: auto;
    padding: 1rem;
    background-color: black;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
}