.blog{
    /* border: 2px solid red; */
    
    padding-top: 10rem;
    padding-bottom: 5rem;
    background: #171923;
}
.blog-heading h1{
    font-size: 5rem;
    text-align: center;
    font-weight: lighter;
    text-decoration-line: underline;
    margin-bottom: 2rem;
}
.blog-heading h2{
    font-size: 4rem;
    text-align: center;
    font-weight: lighter;    
    margin-bottom: 5rem;
    text-decoration-line: underline;
}
.blog-container{
    /* border: 2px solid blue; */
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}
.blog-items{
    /* border: 2px solid turquoise; */
    background: #1A202C;
    padding: 2rem;
    /* background: #394D60; */
    /* background: #1A202C; */
    border-radius: 2rem;
    /* flex: 1; */
}
.blog-items h1{
    margin: 2rem 0;
    font-weight: lighter;
    font-size: 2rem;    
}
.blog-image{
    
    width: 34rem;
    height: 34rem;
    cursor: pointer;
    
    /* padding: 2rem; */
    /* border: 2px solid white; */
    
}

.blog-image img{
    border-radius: 2rem;
    width: 100%;
    height: 100%;
    max-width: 100%;
    /* object-fit: cover; */
    object-fit:cover;
    object-position: center;
    
}

.btn-alpha:first-child{
    margin-right: 2rem;
}

/* ********************** Media Queries******************/

@media(max-width:900px){
    .blog-image{
    
        width: 30rem;
        height: 30rem;
        /* padding: 2rem; */
        /* border: 2px solid white; */
    }
}
@media(max-width:800px){
    .blog-image{
    
        width: 25rem;
        height: 25rem;
        /* padding: 2rem; */
        /* border: 2px solid white; */
    }
}
@media(max-width:450px){
    .blog-heading h1{
        font-size: 3rem;
        
    }
    .blog-heading h2{
        font-size: 3rem;
        
    }
}