.feature03{
    /* border: 2px solid red; */
    padding: 5rem 0;
}
.feature03-heading h1{
    text-align: center;
    font-size: 5rem;
    font-weight: lighter;
    margin-bottom: 5rem;
}
.feature03-container{
    /* border: 2px solid blue; */
    display: flex;
    /* flex-wrap: wrap; */
}
.feature03-text{
    padding: 2rem;
flex: 1 1 60rem;
}
.feature03-text h2{
    font-size: 3rem;
    margin-bottom: 2rem;
    text-align: center;
}
.feature03-text p{
font-size: 1.5rem;
margin-bottom: 3rem;
}
.feature03-text h1{
    font-size: 2rem;
    line-height: 4rem;
    border-bottom: 1px solid grey;
    margin-bottom: 2rem;
    font-weight: 600;
    cursor: pointer;
}
.feature03-image{
    flex: 1 1 60rem;
    width: 40rem;
    height: 40rem;
}
.feature03-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media(max-width:850px){
    .feature03-container{
        
        flex-wrap: wrap;
    }
}
@media(max-width:500px){
    .feature03-heading h1{
     
        font-size: 3rem;
     
    }
    .feature03-text h2{
        font-size: 3rem;
        font-weight: lighter;
    }
}