
.about {
    /* border: 2px solid red; */
    min-height: 100vh;
    padding: 10rem 0;
    /* background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%); */
    
   
    
    /* background: #171923; */
}
.about-heading h1{
    text-align: center;
    font-size: 4rem;
    margin-bottom: 5rem;
    font-weight: lighter;
    text-decoration-line: underline;
    /* border: 2px solid blue; */
}
.about-container{
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
}
.about-expand p{
    font-size: 2rem;     
    text-align: left;   
    /* line-height: 3.5rem; */
    /* text-align: justify; */
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
    /* justify-content:end */
        /* line-height: 4rem; */
    /* text-align: center; */
  }
.about-items{
    /* border: 2px solid green;  */
    
}
.questions{
/* border: 2px solid black; */
display: flex;
justify-content: space-between;
text-align: center;
align-items: center;
padding: 2rem;
background: #394D60;
color: white;
border-bottom: 2px solid black;

}   
.questions h1{
    font-size: 2rem;
    font-weight: lighter;
}
.questions p{
    font-size: 2rem;
    cursor: pointer;
}
/* .answers{
    padding: 2rem;
} */
.answers p{
background: black;
color: white;
font-size: 1.8rem;
padding: 2rem;
} 

.about-image img{
    border: 2px solid turquoise;
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* padding: 2rem; */
    /* margin-top: 2rem; */
    /* border-radius: 100%; */
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}

@media(max-width:600px){
    .about-heading h1{
        
        font-size: 3rem;
        
    }
    .questions h1{
        font-size: 1.8rem;
        
    }
}
@media(max-width:500px){
    
    .questions h1{
        font-size: 1.2rem;
        
    }
}
    