.navbar{
    /* border: 2px solid black; */
    position: fixed;
    width: 100%;
    z-index: 3000;
    
    
    
}
.navbar-desktop{
/* border: 1px solid red; */
background: #171923;
padding: 1rem 0;

}
.desktop-container{
/* border: 1px solid blue; */
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 2px solid turquoise;
}
/* .logo a{
    font-size: 2rem;
    text-decoration: none;
    border: 1px solid turquoise;    
    padding: 2rem;    
    
}
.logo a:hover{
   
    background: turquoise;
    color: black;
    transition: 0.5s;
    
    
} */
.logo{
    width: 5rem;
    height: 5rem;
    /* border: 2px solid red; */
}
.logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.desktop-menulinks{
/* border: 1px solid green; */

}
.desktop-menulinks ul {
    
    display: flex;
    list-style: none;
    font-size: 1.8rem;
    gap: 2rem;
}
.desktop-menulinks li {
    /* border: 1px solid orange; */
    text-transform:uppercase;
    padding: 1.5rem;

}
.desktop-menulinks li :hover{
    border-bottom: 2px solid turquoise;
    transition: 0.5s;
}
.desktop-menulinks a{
    text-decoration: none;
}
.ham-icon{
    
    font-size: 4rem;
    display: flex;
    justify-content: flex-end; 
    z-index: 2000;
    cursor: pointer;
    display: none;
   
}

/* *************** Mobile Menu*********** */
.navbar-mobile{
    display: none;
    /* border: 2px solid green; */
}
.mobile-container{
    
}

.mobile-menulinks{
  position: relative;
  
}

.mobile-menulinks ul {
   
    min-height: 100vh; 

    
    display: grid;
    place-content: center;
    font-size: 2rem;
    
  list-style: none;
  text-align: center;
   background: #171923;
  
   
    
}
.mobile-menulinks li{
    padding: 2rem;
   


}
.mobile-container li:hover{
    border-bottom: 2px solid turquoise;
    transition: 0.5s;
    
}
.mobile-menulinks a{
   
    text-decoration: none;
   
}

/* ********** Media Queries ********** */

@media(max-width:990px){

    .ham-icon{
        display: block;
    }
    .navbar-mobile{
        display: block;
    }
    .desktop-menulinks ul {
     
        display: none;
     
    }
    .mobile-menulinks ul {
        display: grid;
     
        
    }
   
 
}


