.social02{
    /* border: 2px solid red; */
    padding: 5rem 0;
    border-bottom: 2px solid turquoise;
    /* background: white; */
}
.social02-heading h1{
    font-size: 3.5rem;
    font-weight: lighter;
    /* margin-bottom: 2rem; */
    text-align: center;
    margin-bottom: 5rem;
}
.social02-container{
    /* margin-top: 2rem; */
    display: flex;
    /* flex-wrap: wrap; */
    /* gap: 2rem; */
    /* border: 2px solid red; */
    /* padding: 5rem 0; */
}

.social02-form{
    flex: 1 1 50rem;
    height: 40rem;
    border: 1px solid black;
    
    background: white;   
    
}
.social02-input h1{
    color: black;
    text-align: center;
    font-size: 2rem;
    font-weight: lighter;
    margin-bottom: 2rem;
}

.social02-heading p{
    font-size: 1.5rem;
    color: gray;
}
.social02-heading p a{
    color: lightskyblue;  

}
.social02-input{
/* border: 2px solid orange; */
/* text-align: center; */
padding: 2rem;
margin-top: 3rem;
background: white;
color: black;

/* border-radius: 1rem; */
}
.social02-p{
    font-size: 1.5rem;
    margin:1rem 0;
}
.social02-x{
    padding: 0.8rem;
    width: 100%;
    background: white;
color: black;
    border: none;
    outline: 2px solid grey;
    border-radius: 0.5rem;
}
.social02-checkbox{
    margin-top: 2rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;
    
}
.social02-checkbox p{
    font-weight: lighter;
    font-size: 1.5rem;
}
.social02-checkbox a{
    color: lightskyblue;
    font-size: 1.5rem;
}
.social02-btn{
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #3182CE;
    color: white;
    border: none;
    cursor: pointer;
    
}
.social02-btn:hover{
    background-color: #3182ce9e;
    transition: 0.5s;
}
.social02-buttons-container{
    flex: 1 1 50rem;
    height: 40rem;
    border: 1px solid black;
    background-color: white;
    
    
}

.social02-buttons{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 0;
    
    
    /* padding: 5rem 0; */
    
}
/* .social02-buttons h1{
    font-size: 4rem;
    font-weight: lighter;
    margin-bottom: 5rem;

} */
.social02-facebook{
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width:75%;
    background: #314E89;
    color: white;
    cursor: pointer;
    border: none;
}
.social02-google{
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width:75%;
    cursor: pointer;
    border: none;
}
.social02-linkedin{
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width:75%;
    background:#026EAA;
    color: white;
    cursor: pointer;
    border: none;
}
.social02-messenger{
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width:75%;
    background: #0063D1;
    color: white;
    cursor: pointer;
    border: none;
}

@media(max-width:850px){
    .social02-facebook{
        font-size: 1.8rem;
       
    }
    .social02-google{
        font-size: 1.8rem;
      
    }
    .social02-linkedin{
        font-size: 1.8rem;
       
    }
    .social02-messenger{
        font-size: 1.8rem;
       
    }
}
@media(max-width:800px){
    .social02-container{
      flex-wrap: wrap;
    }
   
}

@media(max-width:450px){
    .social02-heading h1{
        font-size: 2.5rem;
     
    }
    .social02-facebook{
        font-size: 1.6rem;
       
    }
    .social02-google{
        font-size: 1.6rem;
      
    }
    .social02-linkedin{
        font-size: 1.6rem;
       
    }
    .social02-messenger{
        font-size: 1.6rem;
       
    }
   
}

