.header{
    /* border: 2px solid red; */
    padding: 5rem 0;
    /* background-color: #92fe9d; */

}
.header-container{
    /* border: 2px solid blue; */
    display: flex;
    /* flex-wrap: wrap; */
    /* max-height: 60rem; */
    
}
.header-social {
    /* border: 2px solid white; */
    position: absolute;
    top: 40%;
    right: 90%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    flex-direction: column;
    font-size: 2rem;
    gap: 2rem;
}
.header-image-items{
    /* border:2px solid white; */
    flex: 1 1 60rem;
    /* max-width: 60rem; */
    display: grid;
    /* place-content: center;  */
    place-items: center;
    /* gap: 2rem;    */
    
    
}

.header-image{
    
margin: 5rem 0;
padding: 0.5rem;
width: 25rem;
height: 26rem;
border: 3px solid white;
border-radius: 100%;
background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
}
.header-image img{
    
    border-radius: 100%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}
.header-text{
    /* border: 2px solid yellow; */
    /* padding: 2rem; */
    flex: 1 1 60rem;    
    display: grid;
    place-items: center;   
    height: 100%;
    
    
}
.header-text h1{
    text-align: center;
    font-size: 5rem;
    font-weight: lighter;
    margin: 5rem 0;
    border-bottom: 1px solid turquoise;
    
}
.header-text p {
    font-size: 2rem;
    line-height: 4rem;
    margin-bottom: 10rem;
    /* border: 2px solid white; */
    text-align: center;
    
}
.button-container {
    /* border: 2px solid black; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.btn-group01{
    /* border: 2px solid red; */
    display: grid;
    gap: 2rem;
    
}

.btn-group01 a{
    text-decoration: none;
}
.btn-group02{
    /* border: 2px solid red; */
    display: grid;
    gap: 2rem;
    
}
.btn-group02 a{
    text-decoration: none;
}


/* ********** Media Queries *************** */

@media(max-width:990px){
    .header-container{
        
        flex-wrap: wrap;
        
    }   

}
@media(max-width:550px){
    .button-container {
        /* border: 2px solid black; */
        gap: 2rem;       
     
    }       
    .header-social {
        /* border: 2px solid white; */
        /* position: absolute;
        top: 40%;
        right: 90%; */
        display: none;
        /* justify-content: center;
        text-decoration: none;
        align-items: center;
        flex-direction: column;
        font-size: 2rem;
        gap: 2rem; */
    }

}



