.card-system{
    /* border: 2px solid red; */
    padding: 10rem 0;
    /* background-color: #F2F2F2; */
}
.card-system-container{
    /* border: 2px solid blue; */
    
    
}
.card-system-heading{
    text-align: center;
    /* border: 2px solid black; */
    margin-bottom: 3rem;
    
}
.card-system-heading h1{
    font-size: 5rem;
    margin-bottom: 2rem;
    font-family:'Courier New', Courier, monospace;
}
.card-system-heading p {
    font-size: 2rem;
    /* border: 2px solid red; */
    line-height: 3rem;    
    font-weight:500;
    
}
.card-system-items{
    /* border: 2px solid green; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}
.card-system-text{
    width: 38.5rem;
    height: 35rem;
    max-width: 38.5rem;
    /* border: 2px solid orange; */
    text-align: center;
    background-color: rgb(81, 142, 130);
    color:  white;
    
}
.card-system-text:nth-child(3){
    background-color:black ;
}
.card-system-text:nth-child(5){
    background-color: white;
    color: black;
}
.card-card h1{
    font-size: 3rem;
    font-weight: lighter;
    margin-top: 5rem;
    margin-bottom: 3rem;    
}
.card-system-para p{
    font-size: 1.5rem;
    line-height: 3rem;
}

.card-system-image{
    width: 38.5rem;
    height: 35rem;
    max-width: 38.5rem;
}
.card-system-image img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}
@media(max-width:450px){
    .card-system-heading h1{
        font-size: 3rem;
        
    }
    .card-system-heading p {
        font-size: 1.5rem;
        
    }
}