.hero06-heading h1{
    padding: 5rem 0;
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
}



.hero06-container {
    width: 100%;
    height: 100vh;
    background-image: url(../06/hero06.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position:relative;
}


.hero06-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.hero06-center h1 {
    font-size: 3rem;
    text-decoration: underline;
    color: white;
    text-transform: uppercase;
    font-weight: lighter;
    /* border: 2px solid white; */
    /* margin-bottom: 2rem; */

}

.hero06-center h2 {
    font-size: 2rem;
    color: white;
    text-transform: capitalize;
    text-align: center;
    /* border: 2px solid whitesmoke; */
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: lighter;
}

.explore_button {
    
    display: block;
    margin: auto;
    text-align: center;
    width: 50%;
    font-size: 2rem;
    color: white;
    text-transform: uppercase;
    background: black;
    
    padding: 1rem;
    text-decoration: none;
}
.explore_button:hover{
    background: white;
    color: black;
    transition: 0.5s;
}  
   

 
 .hero06-social {
     /* border: 2px solid white; */
     position: absolute;
     top: 40%;
     right: 90%;
     display: flex;
     justify-content: center;
     text-decoration: none;
     align-items: center;
     flex-direction: column;
     font-size: 2rem;
     gap: 2rem;
 }

 @media(max-width:990px){
    .hero06-center h1 {
        font-size: 2.5rem;
        
    
    }
 }
 @media(max-width:550px){
    .hero06-center h1 {
        font-size: 1.6rem;
        
    
    }
    .hero06-center h2 {
        font-size: 1.5rem;
     
    }
    .explore_button {
    
        
        width: 60%;
        font-size: 1.5rem;       
        
        padding: 0.5rem;
        
    }
 }
 @media(max-width:450px){
    .hero06-center h1 {
        font-size: 2rem;
        text-align: center;
        
    
    }
    .hero06-center h2 {
        font-size: 1.8rem;
     
    }
    .explore_button {
    
        
        width: 80%;
        font-size: 1.5rem;       
        
        padding: 2rem;
        
    }
 }
 