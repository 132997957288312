.contact{
    
    padding: 10rem 0;
   
    /* border: 1px solid red; */
    background: #171923;
}
.contact-container{
    /* border: 2px solid blue; */
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    gap: 2rem;
    
}
.contact-left{
    flex: 1 1 60rem;
    border: 1px solid turquoise;
    padding: 2rem;
    /* margin: auto; */
    max-width: 100%;
}
.contact-left h1{
    font-size: 3rem;
    text-align: center;
    /* text-decoration-line: underline; */
    font-weight: lighter;
    margin-bottom: 3rem;
    border-bottom: 2px solid turquoise;
}
.contact-left p{
    font-size: 2rem;
    line-height: 4rem;
}

.contact-right{
    flex: 1 1 60rem;
    border: 2px solid turquoise;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    max-width: 100%;
}
.contact-input{
    padding: 1rem;
    /* border: 2px solid red; */
    display: flex;
    width: 100%;
    /* border: none; */
    outline: none;
    border-bottom: 2px solid turquoise;
    background: none;
    color: white;
    font-size: 2rem;
}
.contact-input::placeholder{
    color: white;
}
.contact-textmsgbox{
    
    font-size: 2rem;
    background: none;
    padding: 1rem;
    /* border: none; */
    outline: none;
    /* border: 2px solid turquoise; */
    width: 100%;
    border-bottom: 2px solid turquoise;
    margin-bottom: 2rem;
    color: white;
}
.contact-textmsgbox::placeholder{
    color: white;
}
.btn-primary{
    margin-bottom: 2rem;
}

.contact-social {
    /* border: 2px solid white; */
    /* position: absolute;
    top: 40%;
    right: 90%; */
    display: flex;
    margin-top: 2rem;
    /* flex-direction: column; */
    justify-content: center;
    /* text-decoration: none; */
    /* align-items: center; */
    /* flex-direction: column; */
    font-size: 2rem;
    gap: 2rem;
}

/* ********** Media Queries**************** */

@media(max-width:770px){
    .contact-container{
       flex-wrap: wrap;        
    }
}

@media(max-width:500px){

    .contact-container{
        flex-wrap: wrap;        
     }
    .contact-left p{
        font-size: 1.5rem;
        line-height: 4rem;
    }
}