.hero02 {
    /* border: 2px solid red; */
    height: 100vh;
    background-image: url(./hero02.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.hero02-container{
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;    
    color: white;
}
.hero02-container h1{
    font-size: 4rem;
}
.hero02-container p{
    font-size: 2rem;
    margin-bottom: 2rem;
}
.btn-hero2{
    text-decoration: none;
    font-size: 2rem;
    padding: 1rem;
    border-radius: 2rem;
    text-transform: uppercase;
    background: white;
    color: black;
    border: none;
    border: 2px solid white;
    
    /* border: 2px solid black; */
    letter-spacing: 0.5rem;
    outline: none;
    text-align: center;
    align-items: center;
    cursor: pointer;
    
}
.btn-hero2:hover{
    background: black;
    color: white;
    
    transition: 0.5s;
}
.hero02-heading h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    margin: 5rem 0;

}
