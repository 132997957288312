.hero04{
    /* border: 2px solid red; */
    /* height: 100vh;     */
    background: #3C065E;
    padding: 5rem;
}
.hero4-heading h1{
    font-size: 3rem;
    font-weight: lighter;
    text-align: center;
    margin: 5rem;
}
.hero04-container{
    /* border: 2px solid blue; */
    display: flex;
    /* flex-wrap: wrap; */
    background: #3C065E;
    /* gap: 2rem; */
}
.hero04-textarea{
 flex: 1 1 600px;
 /* margin: auto; */
 padding: 3rem;
 /* border: 2px solid orange; */
 background: #3C065E;
 /* margin-bottom: 5rem; */
 height: 50rem;
 
}
.hero04-textarea h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
    color: white;
    background: #3C065E;
}
.hero04-textarea p {
    margin-bottom: 5rem;
    font-size: 1.8rem;
    line-height: 3rem;
    /* border: 2px solid green; */
    width: 60%;
    color: white;
    background: #3C065E ;
}

.hero04-imagearea{
flex: 1 1 600px;
/* border: 2px solid red; */
/* width: 60rem; */
height: 50rem;

}
.hero04-imagearea img{
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
}
.btn-hero04{
    /* border: 2px solid white; */
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding: 1rem 3rem;
    letter-spacing: 0.5rem;
    text-decoration: none;
    background:#29B7DD ;
    border-radius: 5rem;   
    
}

@media(max-width:920px){
    .hero04-container{
       
        flex-wrap: wrap;
       
    }
}

@media(max-width:500px){
    .hero04-textarea h1 {
        font-size: 2.5rem;
        font-weight: lighter;
       
    }
    .hero04-textarea p {
        
        font-size: 1.5rem;
        line-height: 3rem;
        
        width: 100%;
        
    }
    
}
