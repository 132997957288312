.pricing02{
    /* border: 2px solid red; */
    border-top: 2px solid turquoise;
    padding: 5rem 0;
}
.pricing02-heading h1{
    text-align: center;
    font-size: 5rem;
    font-weight: lighter;
    margin-bottom: 5rem;
    
}
.pricing-topbar{
        /* border: 2px solid red; */
        padding: 5rem;
        display: flex;        
        gap: 5rem;
        border-bottom: 2px solid grey;
}
.pricing-topbar h1{
    font-size: 3rem;
    text-transform: capitalize;
}
.pricing-topbar p{
    font-size: 1.5rem;
    line-height: 4rem;
}
.pricing02-span{
    color: #9F7AEA;
}
.pricing02-container{
    /* border: 2px solid blue; */
    
}
.pricing02-items{
    /* border: 2px solid green; */
    display: flex;
    justify-content: space-between;
    padding: 5rem;
}
.pricing02-items:hover{
    background-color: #2D3748;
    transition: 0.5s;
}
.pricing02-list{
    list-style: none;
    font-size: 1.5rem;
    line-height: 3rem;
}
.pricing02-items h1{
    font-size: 1.8rem;       
}
.pricing02-items h2{
    font-size: 2.5rem;
}
.btn-pricing02{
    border: none;
    outline: none;
    border-radius: 1rem;
    font-size: 2rem;
    padding: 0.5rem 2rem;
 
    background-color: #9F7AEA;
}
.btn-pricing02:hover{
    background-color: #c3abf2;
    cursor: pointer;
}
@media(max-width:780px){
    .pricing02-heading h1{
       
        font-size: 2.5rem;
        text-decoration: none;
    }
    .pricing-topbar{
    flex-wrap: wrap;
    text-align: center;
}
    .pricing02-items{
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;
    }
}