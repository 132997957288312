footer{
    height: 6vh;
    padding: 4rem 0;
    border-top: 2px solid turquoise;
    
}
.footer-container{
    display: grid;
    place-items: center;
}
