.footer-input{
    /* border: 2px solid red; */
    padding: 5rem 0;
    background: #171923;
}
.footer-input-heading h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    margin-bottom: 5rem;
}
.finput-container{
    /* border: 2px solid blue; */
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    justify-content: space-between;
    background:#F7FAFC;
    color: black;
    gap: 2rem;
}
.finput-social-items{
    /* border: 2px solid green; */
    flex: 1 1 20rem;
    /* width: 20rem;
    max-width: 20rem; */
    text-align: center;
    
}
.finput-social-items p{
    margin-top: 2rem;
}
.finput-icons{
    margin-top: 2rem;
    display: flex;
    gap: 2rem;   
    justify-content: center;   
    
    font-size: 2rem;   
    
}
.finput-links{
    /* border: 2px solid white; */
    display: flex;
    gap: 10rem;   
    flex: 1 1 30rem;
    /* width: 30rem;
    max-width: 30rem; */
}

.finput-links h1{
font-size: 2rem;
font-weight: 400;
text-decoration-line: underline;
margin-bottom: 2rem;

}
    

.finput-links ul{
    
    font-size: 1.5rem;
    list-style: none;
    line-height: 3rem;
}
.footer-input a{
    text-decoration: none;    
    color: black;
}
.footer-input a:hover{
    text-decoration: underline;
}
.finput-textarea h1{
    font-size: 2rem;
    font-weight: 400;
    text-decoration: underline;
    margin-bottom: 2rem;
}
.finput-textarea{
    /* border: 2px solid red; */
    flex: 1 1 20rem;
    /* width: 30rem;
    max-width: 30rem; */
}

.btn-finput{
font-size: 2rem;
/* padding: 0.5rem; */
/* background: turquoise; */
background: transparent;
border: none;
outline: none;
cursor: pointer;
}
.finput-input{
    color: black;
}
.finput-input::placeholder{
    color: black;
}
@media(max-width:450px){
    .footer-input-heading h1{
      
        font-size: 2.3rem;
        
    }
}
