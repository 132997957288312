.services-wrapper{
    /* border: 2px solid red; */
    /* background-color: #EDEDED; */
    padding: 10rem 0;
    /* width: 100%;
    max-width: 100%;
    overflow: hidden; */
}

.services-flex{
    display: flex;
   /* border: 2px solid black; */
   padding: 5rem 0;
   /* justify-content: center;
   align-items: center; */
    
}
.services01{
    /* border: 2px solid red; */
    flex: 1 1 40rem;    
    /* display: none; */
    padding: 2rem;
    /* width: 100%; */
}
.services-text h1{
    font-size: 3rem;
    margin-bottom: 3rem;
    font-weight: lighter;
}
.services-text p{
    font-size: 1.8rem;
    margin-bottom: 2rem;
    
}
.btn-services{
    font-size: 2rem;
    padding: 1rem 1.5rem;
    background-color:#292E33;  
    color: white;
    border: none;
    outline: none;    
    cursor: pointer;
    margin-bottom: 3rem;
    
    
}
.btn-services:hover{
    background-color: #6A4AA1;
    transition: 0.5s;
}

/* ************* Services 02************** */
.services02{
/* border: 2px solid blue; */
flex: 1 1 80rem;
/* max-width: 80rem; */


}
.services-container02{
    /* border: 2px solid black; */
    
    display: flex;    
    justify-content: space-between;
    /* align-items: center; */
    /* width: 80rem; */
    
    height: 100%;

    gap: 2rem;   
}
.services-items{
    /* border: 2px solid green; */
    
    text-align: center;
    padding: 2rem;
    
}
.services-items:nth-child(1){
    background-color: #6A4AA1;    
    color: white;
    cursor: pointer;
}
.services-items:nth-child(1):hover{
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    transition: 0.5s;
}
.services-items:nth-child(2){
    background-color:#D01E55;
    color: white;
    cursor: pointer;
}
.services-items:nth-child(2):hover{
    background-image: linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%);
    transition: 0.5s;
}
.services-items:nth-child(3){
    background-color:#292D33;
    color: white;
    cursor: pointer;
}
.services-items:nth-child(3):hover{
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    transition: 0.5s;
}

.services-items i {
    margin: 3rem 0;
    font-size: 3rem;
}
.services-items h1{
    font-size: 2rem;
    margin-bottom: 2rem;
    
}
.services-items p{
    font-size: 1.8rem;
}
/* ************ Media Queries*********** */

@media(max-width:950px){
    .services-flex{
       
        flex-wrap: wrap;
        gap: 2rem;
    }
    
}
@media(max-width:750px){
    .services-container02{
       flex-wrap: wrap;
        
    }
}