.news02{
    /* border: 2px solid red; */
    padding-top: 20rem;
    padding-bottom: 10rem;
    

}

.news02-container{
    /* border: 2px solid blue; */
    width: 50%;
    padding: 5rem 0;
    background-color: black;    
}
.news02-icon{
    font-size: 5rem;
    text-align: center;
    display: block;
    margin: auto;
    color: white;
}
.news02-part01{
    margin-bottom: 2rem;
}
.news02-part01 h1{
    font-size: 3rem;
    text-align: center;
    font-weight: lighter;
    text-transform: capitalize;
    color: white;
}
.news02-part01 p{
    text-align: center;
    font-size: 1.5rem;
}
.news02-part02{
    text-align: center;
    display: flex;
    gap: 2rem;
    justify-content: center;
    /* align-items: center; */
}
.news02-input{
    padding: 0.5rem;
    /* background: grey; */
    border: none;
    background: transparent;
    
    color: white;
    outline: none;
    border-bottom: 2px solid white;  
  
    

}
.news02-input::placeholder{
    color: white;
}
.btn-news02{
    font-size: 2rem;
    padding: 0.5rem 1rem;
    background: white;
    color: black;
    border: none;
    cursor: pointer;
    border: 2px solid white;
}
.btn-news02:hover{
    background: black;
    color: white;
    transition: 0.5s;

}

@media(max-width:850px){
    .news02-part01 h1{
        font-size: 2.5rem;
     
    }
}

@media(max-width:760px){
    .news02-container{
        /* border: 2px solid blue; */
        width: 70%;
     
        
    }
    .news02-part01 h1{
        font-size: 2.3rem;
     
    }
}
@media(max-width:450px){
    .news02-part01 h1{
        font-size: 2.3rem;
     
    }
    .news02-container{
        /* border: 2px solid blue; */
        width: 90%;
     
        
    }
}