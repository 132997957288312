.auth02{
    border-bottom: 2px solid turquoise;
    /* height: 100vh; */
    display: grid;
    place-content: center;
    padding: 10rem 0;
    
    
}
.auth02-h1 h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    /* margin-top: 2rem; */
    margin-bottom: 5rem;
    padding: 2rem 0;
    background: #1A202C;
    color: white;  
    border-bottom: 2px solid turquoise;  
}
    


.auth02-heading{
    /* border: 2px solid blue; */
   
    text-align: center;
    
}
.auth02-heading h1{
    font-size: 3.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
}
.auth02-heading p{
    font-size: 1.5rem;
    color: gray;
}
.auth02-heading p a{
    color: lightskyblue;  

}
.auth02-input{
/* border: 2px solid orange; */
/* text-align: center; */
padding: 2rem;
margin-top: 3rem;
background: white;
color: black;
border-radius: 1rem;

}
.auth02-name{
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* justify-content: space-between;    */
    gap: 10.5rem;
    margin-bottom: 1rem;
    
}
.auth02-name-input{
    display: flex;
    justify-content: space-between;
  
}
.auth02-firstname{
    padding: 0.8rem;
    background: white;
color: black;
    border: none;
    outline: 2px solid grey;
    border-radius: 0.5rem;
}
.auth02-lastname{
    padding: 0.8rem;
    background: white;
    color: black;
    border: none;
    outline: 2px solid grey;
    border-radius: 0.5rem;
}
.auth02-star{
    color: #FC7879;
}
.auth02-name p{
    font-size: 1.5rem;
}
.auth02-p{
    font-size: 1.5rem;
    margin:1rem 0;
}
.auth02-x{
    padding: 0.8rem;
    width: 100%;
    background: white;
color: black;
    border: none;
    outline: 2px solid grey;
    border-radius: 0.5rem;
}
.auth02-checkbox{
    margin-top: 2rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;
    
}
.auth02-checkbox p{
    font-weight: lighter;
    font-size: 1.5rem;
}
.auth02-checkbox a{
    color: lightskyblue;
    font-size: 1.5rem;
}
.auth02-btn{
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #3182CE;
    color: white;
    border: none;
    cursor: pointer;
    
}
.auth02-btn:hover{
    background-color: #3182ce9e;
    transition: 0.5s;
}

