.auth03{
    /* border: 2px solid red; */
    /* height: 100vh; */
    border-bottom: 2px solid turquoise;
    padding: 10rem 0;
    display: grid;    
    place-content: center;
    
}
.auth03-h1 h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    margin-top: 2rem;
    margin-bottom: 5rem;
    padding: 2rem 0;
    background: #1A202C;
    color: white;  
    border-bottom: 2px solid turquoise;  
}
    


.auth03-heading{
    /* border: 2px solid blue; */
   
    text-align: center;
    
}
.auth03-heading h1{
    font-size: 3.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
}
.auth03-heading p{
    font-size: 1.5rem;
    color: gray;
}
.auth03-heading p a{
    color: lightskyblue;  

}
.auth03-input{
/* border: 2px solid orange; */
/* text-align: center; */
padding: 2rem;
margin-top: 3rem;
background: #2D3748;
border-radius: 1rem;
}
.auth03-p{
    font-size: 1.5rem;
    margin:1rem 0;
}
.auth03-x{
    padding: 0.8rem;
    width: 100%;
    background: #2D3748;
    color: white;
    border: none;
    outline: 2px solid grey;
    border-radius: 0.5rem;
}

.auth03-btn{
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #3182CE;
    color: white;
    border: none;
    cursor: pointer;
    
}
.auth03-btn:hover{
    background-color: #3182ce9e;
    transition: 0.5s;
}

.auth03-star{
    color: tomato;
}
