.wordpress{
    /* border: 2px solid red; */
    padding: 5rem 0;
}
.wordpress-heading h1{
    text-align: center;
    font-size: 5rem;
    font-weight: lighter;
    text-decoration-line: underline;
    margin-bottom: 5rem;
}
.wordpress-container{
    /* border: 1px solid blue; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* gap: 2rem; */
    /* padding: 2rem; */
    /* align-items: center; */

}
.wordpress-left{
    /* border: 1px solid white; */
flex: 1 1 60rem;
max-width: 60rem;
padding: 2rem;
}
.wordpress-right{
flex: 1 1 60rem;
max-width: 60rem;
/* border: 1px solid red; */
padding: 2rem;

}
.wordpress-image{
width: 60rem;
height: 40rem;
max-width: 100%;
/* border: 1px solid white; */
margin-bottom: 2rem;

}
.wordpress-image img{
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}
.image-heading{
    font-size: 3rem;
    font-weight: lighter;
    /* padding: 2rem;     */
    text-decoration-line: underline;
}
.wordpress-para{
    font-size: 2rem;
    line-height: 4rem;
    margin-bottom: 2rem;
    text-align: justify;
    /* text-align: left; */
    
}
/* ************ Media Queries********** */

@media(max-width:700px){
    .wordpress-right{
        max-width: 50rem;       
                
        }
        .wordpress-left{
            max-width: 50rem;
        
            }
            .wordpress-image{
                width: 50rem;
                
              }
              .wordpress-heading h1{
             
                font-size: 3.5rem;                
            }
}

@media(max-width:450px){
    .wordpress-right{
        max-width: 40rem;       
                
        }
        .wordpress-left{
            max-width: 40rem;
        
            }
            .wordpress-image{
                width: 40rem;
                
              }
              .wordpress-heading h1{
             
                font-size: 3rem;                
            }
}

@media(max-width:600px){

    
    .wordpress-heading h1{
        
        font-size: 3rem;        
    }
}

@media(max-width:450px){
    .wordpress-heading h1{
        font-size: 2.8rem;        
    }
   
}