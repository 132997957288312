.feature02{
    /* border: 2px solid red; */
    padding-bottom: 5rem;
}
.feature02-heading h1{
    font-size: 5rem;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 5rem;
}
.feature02-container{
    /* border: 2px solid blue;     */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* align-items: center; */
    /* text-align: center; */
}
.feature02-items{
    /* border: 2px solid green; */
    text-align: center;
    flex: 1 1 38rem;
    background-color: white;
    padding: 2rem;
}
.feature02-image{
    width: 7rem;
    height: 7rem;
    /* border: 2px solid red; */
    
    display: block;
    margin: auto;
    margin-bottom: 2rem;
    
}
.feature02-image img{
    
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.feature02-h1 h1{
    color: black;
    font-size: 2rem;
    font-weight: lighter;
    margin-bottom: 2rem;
}
.feature02-text p{
    font-size: 1.5rem;
    color: black;
    line-height: 3rem;
}

/* ---------------------------------- */

@media(max-width:450px){
    .feature02-heading h1{
        font-size: 3rem;
        
    }
}