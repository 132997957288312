.social01{
    /* border: 2px solid red; */
    padding: 20rem 0;
    border-bottom: 2px solid turquoise;
}
.social01-container{
    /* border: 2px solid blue; */
    /* display: flex; */
}
.social01-buttons{
    /* border: 2px solid orange; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    
}
.social01-buttons h1{
    font-size: 4rem;
    font-weight: lighter;
    margin-bottom: 5rem;

}
.social01-facebook{
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width:50%;
    background: #314E89;
    color: white;
    cursor: pointer;
    border: none;
}
.social01-google{
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width:50%;
    cursor: pointer;
    border: none;
}
.social01-linkedin{
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width:50%;
    background:#026EAA;
    color: white;
    cursor: pointer;
    border: none;
}
.social01-messenger{
    font-size: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width:50%;
    background: #0063D1;
    color: white;
    cursor: pointer;
    border: none;
}

@media(max-width:850px){
    .socia01-facebook{
        font-size: 1.8rem;
        
    }
    .socia01-google{
        font-size: 1.8rem;
      
    }
    .socia01-linkedin{
        font-size: 1.8rem;
       
    }
    .socia01-messenger{
        font-size: 1.8rem;
       
    }
}


@media(max-width:450px){
    .social01-buttons h1{
        font-size: 2.5rem;      
        
    }

    .social01-facebook{
        font-size: 1.6rem;
        width:75%;
        
    }
    /* .socia01-facebook{
        font-size: 1.6rem;
       
    } */
    .social01-google{
        font-size: 1.6rem;
        width:75%;
      
    }
    .social01-linkedin{
        font-size: 1.6rem;
        width:75%;
       
    }
    .social01-messenger{
        font-size: 1.6rem;
        width:75%;
       
    }
   
}

