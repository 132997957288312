.hero05{
    /* border: 2px solid red; */
    /* height: 100vh; */
    /* min-height: 70vh; */
    /* padding: 5rem 0; */
    
    /* background: white; */
    /* color: black; */
    background: lightslategray;
}
.hero05-heading h1{
    padding: 5rem 0;
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;   
    

}
.hero05-container{
    padding: 10rem 0;
    /* border: 2px solid blue; */
    display: flex; 
    
}
.hero05-textarea{
    flex: 1 1 60rem;
    height: 40rem;
    /* border: 2px solid red; */
    padding: 4rem;
    max-width: 100%;
}
.hero05-textarea h1{
    font-size: 2.5rem;
    font-weight: lighter;
    margin-bottom: 2rem;
    text-decoration: underline;
    color: black;
}
.hero05-textarea p{
    font-size: 2rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
}
.hero05-videoarea{
    flex: 1 1 60rem;
    height: 40rem;
    /* border: 2px solid green;             */
    /* max-width: 100%; */
}
.hero05-group{
    
    display: flex;
    gap: 2rem;
    /* border: 2px solid black; */
    /* width: 30rem;     */
}
.btn-hero05{
    font-size: 2rem;
    padding: 1rem;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
}
iframe{
    width: 60rem;
    height: 40rem;
    max-width: 100%;
}


@media(max-width:1050px){
    .hero05-container{
        flex-wrap: wrap;        
    }
}


@media(max-width:550px){
    .hero05-textarea h1{
        font-size: 2rem;
        text-align: center;
        
    }
    .hero05-textarea p{
        font-size: 1rem;
        line-height: 2.5rem;
        /* margin-bottom: 2rem; */
    }
    .btn-hero05{
        font-size: 1.5rem;
        padding: 1rem;
        background-color: black;
        color: white;
        border: none;
        cursor: pointer;
    }
}