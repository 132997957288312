.pdetails{
    /* border: 2px solid red; */
    padding-top: 15rem;
}
.pdetails-heading h1{
    text-align: center;
    font-size: 5rem;
    font-weight: lighter;
    margin-bottom: 5rem;
}
.pdetails-container{
    /* border: 2px solid blue; */
    background-color: white;
    color: black;
    padding: 5rem;
}
.pdetails-image{
    width: 50rem;
    height: 60rem;
    display: block;
    margin: auto;   

}
.pdetails-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.pdetails-h1 h1{
font-size: 3rem;
text-align: 3rem;
margin-top: 3rem;
margin-bottom: 2rem;
color: tomato;
}
.pdetails-price h1{
    font-size: 2rem;
    margin-bottom: 2rem;
}
.pdetails-details p{
    font-size: 1.5rem;
    line-height: 3rem;
    text-align: justify;
    margin-bottom: 2rem;
}
.pdetails-features h1{
    font-size: 3rem;
    margin-bottom: 2rem;    
}
.pdetails-list1{
    list-style: none;
    font-size: 1.5rem;    
    margin-bottom: 2rem;
}
.pdetails-list2{
    list-style: none;
    font-size: 1.5rem;    
    margin-bottom: 2rem;
}
.btn-pdetails{
    font-size: 2rem;
    padding: 0.5rem 2rem;
    background: black;
    color: white;
    cursor: pointer;    
    display: block;
    margin: auto;
    margin-bottom: 2rem;
}
.pdetails-icon{
    font-size: 1.5rem;
}