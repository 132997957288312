.footercomp{
    /* border: 2px solid turquoise; */
    padding-top: 5rem;
    
    
}
.footercomp-heading h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    margin-top: 2rem;
    padding: 2rem 0;
    /* background: #1A202C; */
    color: white;
}
.fcomp-container{
    /* border: 2px solid blue; */
    border-bottom: 2px solid grey;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    background: #F7FAFC;
    color: black;
}
    
    
.fcomp-items{
    /* border: 2px solid green;     */
    flex: 1 1 25rem;
}
.fcomp-heading h1{
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight:400;
}
.fcomp-list ul{
    /* border: 2px solid red; */
    list-style: none;
    line-height: 3rem;
    font-size: 1.3rem;
    
}
.fcomp-list a{
    color: black;    
}
.fcomp-images{
width: 15rem;
/* flex: 1 1 20rem; */
}
.fcomp-images img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

@media(max-width:400px){
    .footercomp-heading h1{
      
        font-size: 2.5rem;
        
    }
}

