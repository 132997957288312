.webcomp{
    /* border: 2px solid red; */
    padding: 5rem 0;
    background: #171923;
}
/* .btn-overlay{
    font-size: 2rem;
    padding: 1rem;
    border: 2px solid turquoise;
    background: transparent;

    color: white;
    cursor: pointer;
    width: 25rem;
    display: block;
    margin: auto;
  } */


.webcomp-heading h1{
    font-size: 5rem;
    text-align: center;
    font-weight: lighter;
    text-decoration-line: underline;
    margin-bottom: 5rem;
    
}
.webcomp-container{
    /* border: 1px solid blue; */
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.webcomp-image-container {
   width: 28rem;
   height: 28rem;
   position: relative;
   /* border: 1px solid orange; */
}
.webcomp-image-container img{
    
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}

.webcomp-image-content{
    
    position: absolute;
    z-index: 2;  
    top: 20%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%,-50%);    
    transition: all 0.3s ease-in-out 0.1s;  
    cursor: pointer;
    /* border: 2px solid white; */

}

.webcomp-image-container:hover .webcomp-image-content{
    opacity: 1;
    top: 50%;
    left: 50%;   
    cursor: pointer; 
} 
.webcomp-image-container::after{
content: '';
display: block;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.479);
opacity: 0;
z-index: 1;
transform: scaleY(0);
transform-origin: 100% 100%;
transition: all 0.3s ease-in-out;
cursor: pointer;
}
.webcomp-image-container:hover::after{
    opacity: 1;
    transform: scaleY(1);
    cursor: pointer;
}

.webcomp-items h1{
    text-align: center;
    /* margin: 2rem 0; */
    font-size: 2rem;
    font-weight: lighter;
    border: 1px solid turquoise;
    padding: 1rem;
    
    
}
.webcomp-items a{
    /* display: none; */
    text-decoration: none;
}
.btn-responsive{
    display: none;
    font-size: 2rem;
    padding: 1rem;
    border: 2px solid turquoise;
    background: transparent;
    /* border-radius: 1rem; */
    color: white;
    cursor: pointer;
    /* width: 25rem; */
    text-decoration: none;    
  }

  .btn-responsive:hover{
    border: 2px solid white;
    background: white;
    color: black;
    transition: 0.5s;
  }

/* ************* Media Queries *************** */

@media(max-width:1030px){

    
    .btn-responsive{
        display: block;
        width: 100%;
        text-align: center;
    }
    
      .webcomp-image-content{
    
        display: none;
    
    }
    .webcomp-image-container:hover .webcomp-image-content{
        opacity: 0;        
    }
    .webcomp-image-container::after{
        display: none;
        }
        .webcomp-image-container:hover::after{
            opacity: 0;         
        }
    
}

@media(max-width:600px){

    
    .web-heading h1{
        font-size: 3rem;
     
    }
}

@media(max-width:450px){
    .webcomp-heading h1{
        font-size: 2.8rem;
        
    }
   
}


