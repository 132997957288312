*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
html{
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body{
  
  background: var(--color-bg);
  color: var(--color-white);  
}
a{
  color: var(--color-white);  
  /* transition: var(--transition); */
}

:root{
  --color-bg:#1A202C;
  --color-bg-variant:rgba(0, 0, 0, 0.349);
  --color-primary:lightblue;
  --color-primary-variant:rgba(173, 216, 230, 0.301);
  --color-white:white;
  --color-light:lightgray;
  --transition: all 400ms ease;
}
.container{
  max-width: 1200px;
  width: 90%;
  margin: auto;
}
.btn-primary{
  font-size: 2rem;
  padding: 1rem;
  border: 2px solid turquoise;
  background: transparent;
  /* border-radius: 1rem; */
  color: white;
  cursor: pointer;
  width: 25rem;
  display: block;
  margin: auto;
  text-decoration: none;
  text-align: center;
}


.btn-primary:hover{
  border: 2px solid white;
  background: white;
  color: black;
  transition: 0.5s;
}
.btn-secondary{
  background: #171923;
  font-size: 2rem;
  padding: 1rem;
  border: 2px solid turquoise;
  background: transparent;
  color: white;
  cursor: pointer;
  width: 25rem;
  display: block;
  margin: auto;
}
.btn-secondary:hover{
  border: 2px solid white;
  background: white;
  color: black;
  transition: 0.5s;
}


/* *********** buttons for cards************ */

.btn-alpha{
  font-size: 2rem;
  padding: 1rem;
  border: 2px solid turquoise;
  background: transparent;
  /* border-radius: 1rem; */
  color: white;
  cursor: pointer;
  /* width: 25rem; */
  
}
.btn-alpha:hover{
  border: 2px solid white;
  background: white;
  color: black;
  transition: 0.5s;
}
.btn-beta{
  background: #171923;
  font-size: 2rem;
  padding: 1rem;
  border: 2px solid turquoise;
  background: transparent;
  color: white;
  cursor: pointer;
  /* width: 25rem; */
  
}
.btn-beta:hover{
  border: 2px solid white;
  background: white;
  color: black;
  transition: 0.5s;
}

/* ------------------------------------------------------------------------- */

/* Template CSS */

.block-index{
  padding-bottom: 5rem;
  background: #171923;
}
.template{
  /* border: 2px solid red; */
  
  padding-top: 10rem;
  padding-bottom: 5rem;
  background: #171923;
}
.template-container60rem{
  max-width: 60rem;
  width: 90%;
  /* margin: auto; */
}
.template-container80rem{
  max-width: 80rem;
  width: 90%;
  /* margin: auto; */
}
.template-container h1{
  font-size: 5rem;
  text-align: center;
  font-weight: lighter;
  text-decoration-line: underline;
  margin-bottom: 2rem;
  /* color:turquoise; */
}
.template-container h2{
  font-size: 3.8rem;
  text-align: center;
  font-weight: lighter;
  text-decoration-line: underline;
  margin-bottom: 2rem;
  color:turquoise;
}
.template-container h3{
  font-size: 3rem;
  text-align: center;
  font-weight: lighter;
  text-decoration-line: underline;
  margin-bottom: 2rem;
  
}
.template-block{
  border: 2px solid turquoise;
  padding: 4rem;
}
.template-container p{
  font-size: 2rem;    
  line-height: 3.5rem;
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* justify-content:end */
      /* line-height: 4rem; */
  /* text-align: center; */
}
.template-container a{
  font-size: 2rem;  
  color: turquoise;
  text-decoration: none;
  /* line-height: 3.5rem; */
  /* text-align: justify; */
  
  /* justify-content:end */
      /* line-height: 4rem; */
  /* text-align: center; */
}
.template-container ul{
  font-size: 2rem;
}


.template-container img{
  border: 2px solid turquoise;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* padding: 2rem; */
  /* margin-top: 2rem; */
  /* border-radius: 100%; */
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
}


.template-buttons{
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between; 

}
.template-back{
  background: #171923;
  font-size: 2rem;
  padding: 1rem;
  border: 2px solid turquoise;
  background: transparent;
  color: white;
  cursor: pointer;
  /* width: 25rem; */
  
}
.template-back:hover{
  border: 2px solid white;
  background: white;
  color: black;
  transition: 0.5s;
}
.template-next{
  background: #171923;
  font-size: 2rem;
  padding: 1rem;
  border: 2px solid turquoise;
  background: transparent;
  color: white;
  cursor: pointer;
  /* width: 25rem; */
  
}
.template-next:hover{
  border: 2px solid white;
  background: white;
  color: black;
  transition: 0.5s;
}
/* ------------Media Queries------------- */
@media(max-width:850px){
  .template-container h1{
    font-size: 3.5rem;
    
  }
  .template-container h2{
    font-size: 3rem;    
  }
  .template-container h3{
    font-size: 2.8rem;    
  }
  .template-container p{
    font-size: 1.8rem;    
   
  }
}

@media(max-width:770px){
  .template-container h1{
    font-size: 3rem;    
  }
  .template-container h2{
    font-size: 2.5rem;    
  }
  .template-container h3{
    font-size: 2.3rem;    
  }
  .template-container p{
    font-size: 1.5rem;    
   
  }
}

@media(max-width:500px){

  .template-container h1{
    font-size: 2rem;       
  }
  .template-container h2{
    font-size: 1.7rem;    
  }
  .template-container h3{
    font-size: 1.5rem;    
  }
  .template-container p{
    font-size: 1.3rem;    
   
  }
}

/* ---------------------------------------------------------------------------------------- */