.footersocial{
    /* border: 2px solid red; */
}
.fsocial-container{
    /* border: 2px solid grey; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background-color: #F7FAFC;
    color: black;
}
.fsocial-heading p{
    font-size: 1.5rem;
}
.fsocial-icons{
    font-size: 2rem;
    display: flex;
    gap: 2rem;    
}