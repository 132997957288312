.Pricing01{
    /* border: 2px solid red; */
    padding-top: 10rem;
    padding-bottom: 5rem;
}
.Pricing01-heading h1,h2, p{
    text-align: center;
}
.Pricing01-heading h1{
    font-size: 5rem;
    font-weight: lighter;
    margin-bottom: 5rem;
    text-decoration: underline;
    
}
.Pricing01-heading h2 {
    font-size: 4rem;
    font-weight: lighter;
}
.Pricing01-heading p{
    font-size: 1.5rem;
    margin-bottom: 3rem;
}
.Pricing01-container{
    /* border: 2px solid blue; */
    display: flex;
    gap: 2rem;
    
}
.pricing-items{
    /* border: 2px solid green; */
    flex: 1 1 38rem;
    height: 40rem;
    border-radius: 2rem;
}
/* .pricing-items:hover{
    background-color:#2D3748 ;
} */
.pricing-items:nth-child(2){
background-color: tomato;

} 


.pricing-items h1{
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    /* margin-bottom: 2rem; */
}
.pricing-items p{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
}
.pricing-top{


    
    
}
.pricing-bottom{
    /* border: 2px solid brown; */
    padding: 2rem;
    
    
}
.pricing-list {
    list-style: none;
    font-size: 1.5rem;
    line-height: 3rem;
    margin-bottom: 2rem;   
        
}

.btn-pricing{
    border: none;
    outline: none;
    border-radius: 1rem;
    font-size: 2rem;
    padding: 0.5rem 2rem;
    display: block;
    margin:auto;
    background-color: #FEB2B2;    
}
.btn-pricing:hover{
    background-color: rgb(244, 126, 126);
    cursor: pointer;
}
.pricing-bottom{
    background-color:#2D3748 ;
}
.price-color{
    color: green;

}

@media(max-width:790px){
    .Pricing01-container{
     flex-wrap: wrap;
        
    }
}
@media(max-width:500px){
    .Pricing01-heading h2 {
        font-size: 3rem;
        margin-bottom: 2rem;
        
    }
}