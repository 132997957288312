.skills{
    /* border: 2px solid red; */
    padding-top: 10rem;
    padding-bottom: 5rem;
    background: #171923;
}
.skills-Heading{
    text-align: center;    
    margin-bottom: 3rem;
    /* border: 1px solid white; */
}
.skills-Heading h1{
    font-size: 5rem;
    font-weight: lighter;
    margin-bottom: 3rem;
    text-decoration-line: underline;
}
.skills-Heading p {
    font-size: 2rem;    
}
.skills-container{
    /* border: 2px solid blue; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.skill-items{

border: 1px solid turquoise;
padding: 2rem;
/* width: 38rem; */
flex: 1 1 38rem;
}
.skill-items:hover{
    background: #394D60;
    transition: 0.5s;
    cursor: pointer;
}

.skill-items h1{
    font-size: 2.5rem;
    font-weight: lighter;
    text-align: center;
    text-decoration-line: underline;
    margin-bottom: 2rem;
    color: turquoise;
}
.icon-colors{
    font-size: 3rem;
    display: block;
    text-align: center;
    margin-bottom: 2rem;
}
.skill-items ul{
    list-style: none;
    font-size: 2rem;
    line-height: 4rem;   
    
}

/* *********** Media Queries************* */

@media(max-width:600px){

    
    .skills-Heading h1{
        font-size: 3rem;
     
    }
}
@media(max-width:450px){

    
    .skills-Heading h1{
        font-size: 2.8rem;     
    }
}