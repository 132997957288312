.hero03 {
    /* border: 2px solid white; */
    height: 100vh;
    background: #181A1B;
    

}
.hero03-container{
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;    
    color: black;
    
}
.hero03-container h1{
    font-size: 5rem;
    color: white;
}
.hero3-span{
    /* font-size: 2rem; */
    color:#48BB78;
}
.hero03-container p{
    color: white;
    font-size: 2rem;
    margin-bottom: 2rem;
}
.hero03-container a{
    text-decoration: none;
    color: black;
    /* color: white; */
    margin-top: 2rem;
    font-size: 2rem;
    color:cornflowerblue;
    text-decoration: underline;
}
.btn-hero3{
    text-decoration: none;
    font-size: 2rem;
    padding: 1rem 2rem;
    text-transform: uppercase;
    background: #48BB78;
    color: white;
    border: none;    
    /* border: 2px solid black; */
    letter-spacing: 0.5rem;
    outline: none;
    text-align: center;
    align-items: center;
    cursor: pointer;
    
}
.btn-hero3:hover{
    background: black;
    color: white;
    transition: 0.5s;
}
.hero03-heading h1{
    
    text-align: center;
    font-size: 3rem;
    font-weight: lighter;
    margin: 5rem 0;

}
